// FloatingLabelInput.tsx
import { ComponentProps, LegacyRef, forwardRef, createElement } from 'react';
// Import the CSS file for styles
type FloatingLabelInputProps = {
  label: string;
  required?: boolean;
  inputType?: 'input' | 'textarea';
} & ComponentProps<'input' | 'textarea'>;
const FloatingLabelInput = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  FloatingLabelInputProps
>(({ required = false, label, inputType = 'input', ...props }, ref) => {
  // const [focused, setFocused] = useState(false);
  // const [filled, setFilled] = useState(false);

  // const handleFocus = () => setFocused(true);
  // const handleBlur = (e: any) => {
  //   setFocused(false);
  //   setFilled(e.target.value !== '');
  // };

  return (
    <div className="floating-label-input-container">
      <div className={`input-wrapper focused`}>
        {/* <input
          ref={ref as LegacyRef<HTMLInputElement>}
          className="custom-input"
          {...props}
          onFocus={handleFocus}
          onBlur={handleBlur}
        /> */}
        {createElement(inputType, {
          ref: ref as LegacyRef<HTMLInputElement>,
          className: 'custom-input',
          ...props,
          // onFocus: handleFocus,
          // onBlur: handleBlur,
          required,
        })}
        <label className="floating-label">
          {label}
          <span
            style={{
              color: 'red',
              fontSize: '0.7rem',
              transition: 'font-size 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
            }}
          >
            {required && '*'}
          </span>
        </label>
        <fieldset
          className="fieldset-custom"
          style={{
            padding: '14px',
            borderRadius: '32px',
            position: 'absolute',
            right: '-1px',
            left: '-1px',
            pointerEvents: 'none',
            top: '-11px',
            bottom: '3px',
            border: '1px solid var(--input_active_outline-color)',
          }}
        >
          <legend
            style={{
              overflow: 'hidden',
              float: 'unset',
              // ...(focused && { visibility: 'hidden' }),
              visibility: 'hidden',
              width: 'auto',
              maxWidth: '100%',
              transition: 'max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms',
              whiteSpace: 'nowrap',
              display: 'block',
            }}
          >
            <span
              style={{
                paddingLeft: 5,
                paddingRight: 5,
                display: 'inline-block',
                opacity: 0,
                visibility: 'visible',
              }}
            >
              {label}
            </span>
          </legend>
        </fieldset>
      </div>
    </div>
  );
});

export default FloatingLabelInput;
