import { format } from 'date-fns';

export const blobjob = (fileBlob: any, fileName: any) => {
  const url = URL.createObjectURL(new Blob([fileBlob]));
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  timeout = 300,
): (...args: Parameters<T>) => void {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}

export const convertOrder = (paramsOrderBy: string | null) => {
  if (paramsOrderBy) {
    const orderData = JSON.parse(paramsOrderBy);
    return `${Object.keys(orderData).map(
      (el) => `${el} ${orderData[el] === 'descend' ? 'desc' : ''}`,
    )}`;
  }
  return undefined;
};

export const handleResponse = async (response: Response) => {
  if (!response.ok) return 'error';
  try {
    blobjob(await response.blob(), `${format(new Date(), 'dd/MM/yyyy HH:mm')}.pdf`);
  } catch (error) {
    console.error('Error processing blob:', error);
  }
  return 'downloaded_file';
};
