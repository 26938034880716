import React from 'react';
import { Button, Col, Dropdown, Flex, MenuProps, Modal, Row, Space } from 'antd';
import { IGetJobApplies } from '../../types';
import { useAppDispatch } from 'hooks';
import { modalDispatcher } from 'components/utils/Modal/services/modalSlice.ts';
import { MODALS } from '_constants';
import { format } from 'date-fns';
import { JOB_APPLIES_LIST, JobAppliesEnum } from 'features/jobApplies/constants';
import { DownloadOutlined, DownOutlined } from '@ant-design/icons';
import StatusCard from 'components/ui/StatusCard/StatusCard';
import { useLazyDownloadFileQuery } from 'api';

interface Props {
  data: IGetJobApplies;
}

type TRow = { title: RowTitleEnum; value: unknown };

enum RowTitleEnum {
  STATUS = 'Status',
  REJECT_EMAIL = 'Reject email',
  ID = 'ID',
  CREATION_DATE = 'Creation Date',
  FULL_NAME = 'Full name',
  EMAIL = 'Email',
  PHONE_NUMBER = 'Phone number',
  SALARY = 'Minimum salary',
  CV = 'Cv',
  LETTER = 'Cover letter',
}

const JobApplyModal: React.FC<Props> = ({ data }) => {
  const dispatch = useAppDispatch();
  const { openModal, closeModal } = modalDispatcher(dispatch);
  const [downloadFile] = useLazyDownloadFileQuery();
  const onModalClose = () => {
    closeModal({ modalType: MODALS.JOB_APPLY_MODAL });
  };

  const handleOpenConfirmModal = (actionType: string) => {
    openModal({
      modalType: MODALS.JOB_CONFIRM_MODAL,
      props: {
        actionType,
        title: `Are you sure to change applicant's status to "${String(
          JobAppliesEnum[actionType as keyof typeof JobAppliesEnum],
        ).replace(/_/g, ' ')}"`,
        confirmButtonText: 'Change now',
        data: data,
        cancelButtonText: 'Cancel',
        cancelAction: () => closeModal({ modalType: MODALS.JOB_CONFIRM_MODAL }),
      },
    });
  };

  const rowData: TRow[] = [
    { title: RowTitleEnum.STATUS, value: data.status },
    { title: RowTitleEnum.REJECT_EMAIL, value: data.rejectEmailSent },
    { title: RowTitleEnum.ID, value: data.id },
    { title: RowTitleEnum.CREATION_DATE, value: data.createdAt },
    { title: RowTitleEnum.FULL_NAME, value: `${data.firstName} ${data.lastName}` },
    { title: RowTitleEnum.EMAIL, value: data.email },
    { title: RowTitleEnum.PHONE_NUMBER, value: data.phoneNumber },
    { title: RowTitleEnum.SALARY, value: data.minSalary },
    { title: RowTitleEnum.CV, value: data.cvFileId },
    { title: RowTitleEnum.LETTER, value: data.coverLetter },
  ];

  const renderRow = (el: TRow) => {
    if (el.title === RowTitleEnum.STATUS) {
      return (
        <Row gutter={16} key={el.title as string}>
          <Col span={6} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)' }}>
            <span>{el.title}</span>
          </Col>
          <Col span={18} style={{ paddingLeft: 16, display: 'flex', justifyContent: 'start' }}>
            <StatusCard
              status={el.value as number}
              text={JobAppliesEnum[el.value as keyof typeof JobAppliesEnum]}
            />
          </Col>
        </Row>
      );
    }
    if (el.title === RowTitleEnum.CREATION_DATE) {
      return (
        <Row gutter={16} key={el.title as string}>
          <Col span={6} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)', paddingTop: 8 }}>
            <span>{el.title}</span>
          </Col>
          <Col
            span={18}
            style={{
              paddingLeft: 16,
              display: 'flex',
              justifyContent: 'start',
              fontWeight: 600,
              paddingTop: 8,
            }}
          >
            {format(data.createdAt, 'dd/MM/yyyy hh:mm')}
          </Col>
        </Row>
      );
    }
    if (el.title === RowTitleEnum.CV) {
      return (
        <Row gutter={16} key={el.title as string}>
          <Col span={6} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)', paddingTop: 8 }}>
            <span>{el.title}</span>
          </Col>
          <Col span={18} style={{ display: 'flex', justifyContent: 'start', paddingTop: 8 }}>
            <a
              title={''}
              style={{ display: 'flex', gap: 8, alignItems: 'center' }}
              rel="noreferrer"
              role={'button'}
              onClick={(e) => {
                e.stopPropagation();
                downloadFile(el.value as string);
              }}
            >
              <DownloadOutlined />
              Download CV
            </a>
          </Col>
        </Row>
      );
    }
    if (el.title === RowTitleEnum.REJECT_EMAIL) {
      return (
        <React.Fragment key={el.title as string}>
          {el.value ? (
            <Row gutter={16}>
              <Col span={6} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)', paddingTop: 8 }}>
                <span>{el.title}</span>
              </Col>
              <Col
                span={18}
                style={{ paddingLeft: 16, textAlign: 'start', fontWeight: 600, paddingTop: 8 }}
              >
                <StatusCard status={1} text={'Sent'}></StatusCard>
              </Col>
            </Row>
          ) : null}
        </React.Fragment>
      );
    }
    return (
      <Row gutter={16} key={el.title as string}>
        <Col span={6} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.06)', paddingTop: 8 }}>
          <span>{el.title}</span>
        </Col>
        <Col
          span={18}
          style={{ paddingLeft: 16, textAlign: 'start', fontWeight: 600, paddingTop: 8 }}
        >
          <span>{el.value as string}</span>
        </Col>
      </Row>
    );
  };

  const items: MenuProps['items'] = Object.keys(JOB_APPLIES_LIST)
    .map((el) => ({
      label: el.replace(/_/g, ' '),
      key: JOB_APPLIES_LIST[el as keyof typeof JOB_APPLIES_LIST],
    }))
    .filter((el) => el.key !== data.status);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    handleOpenConfirmModal(e.key);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Modal
      open={true}
      title={
        <Col span={22}>
          <Flex justify={'space-between'} align={'center'}>
            <span>View Details</span>
            <Dropdown menu={menuProps}>
              <Button style={{ backgroundColor: '#FFB133', border: 'none' }}>
                <Space style={{ color: 'white' }}>
                  Change status
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Flex>
        </Col>
      }
      onCancel={onModalClose}
      centered
      footer={false}
    >
      <Flex vertical style={{ marginTop: 32 }}>
        {rowData.map((el) => renderRow(el))}
      </Flex>
    </Modal>
  );
};

export default JobApplyModal;
