import { api } from 'api';
import { globalApiRoutes } from '_constants/constants';
import { IDistinctColumnValues, IRequestData, TBase36Id } from '../types';
import { blobjob, handleResponse } from '../utils/helpers.ts';
import { format } from 'date-fns';
import { TABLE_KEY_ENUM } from '../components';
import { openSuccessSnackBar } from '../components/ui/SnackBar/services/SnackBarSlice.ts';

const withTags = api.enhanceEndpoints({
  addTagTypes: ['Configs'],
});

export const globalApi = withTags.injectEndpoints({
  endpoints: (builder) => ({
    getColumnValues: builder.query<{ pageSize: number; data: string[] }, IDistinctColumnValues>({
      query: ({ tableName, ...params }) => ({
        url: globalApiRoutes.COLUMN_VALUES(tableName),
        params,
      }),
    }),
    saveConfig: builder.mutation<void, { [key: string]: string }>({
      query: (configs) => ({
        url: globalApiRoutes.CONFIGS,
        method: 'POST',
        body: { configs },
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            dispatch(openSuccessSnackBar({ message: 'Table config has been saved successfully' }));
          })
          .catch(() => {});
      },
      invalidatesTags: (_, error) => (error ? [] : ['Configs']),
    }),
    deleteConfig: builder.mutation<void, string[]>({
      query: (keys) => ({
        url: globalApiRoutes.CONFIGS,
        method: 'DELETE',
        body: { keys },
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            dispatch(openSuccessSnackBar({ message: 'Table config has been saved successfully' }));
          })
          .catch(() => {});
      },
      invalidatesTags: (_, error) => (error ? [] : ['Configs']),
    }),
    getConfig: builder.query<{ [key: string]: string }, { Keys: TABLE_KEY_ENUM }>({
      query: (params) => ({
        url: globalApiRoutes.CONFIGS,
        params,
      }),
      providesTags: ['Configs'],
    }),
    downloadFile: builder.query<void, TBase36Id>({
      query: (id) => ({
        url: globalApiRoutes.DOWNLOAD_FILE(id),
        responseHandler: (response: Response) => handleResponse(response),
        cache: 'no-cache',
      }),
    }),
    exportData: builder.query<
      void,
      { tableKey: TABLE_KEY_ENUM; ExportType: string } & IRequestData
    >({
      query: ({ tableKey, ...params }) => ({
        url: globalApiRoutes.EXPORT_DATA(tableKey),
        params,
        responseHandler: async (response: any) => {
          const contentType = response.headers.get('Content-Type');
          let fileExtension = '';

          if (contentType === 'application/pdf') {
            fileExtension = '.pdf';
          } else if (contentType === 'application/csv') {
            fileExtension = '.csv';
          } else if (
            contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ) {
            fileExtension = '.xlsx';
          }

          if (response.ok) {
            blobjob(
              await response.blob(),
              `${tableKey} ${format(new Date(), 'dd/MM/yyyy hh:mm')}${fileExtension}`,
            );
          }
          return 'text';
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useLazyExportDataQuery,
  useGetColumnValuesQuery,
  useLazyGetColumnValuesQuery,
  useSaveConfigMutation,
  useGetConfigQuery,
  useDeleteConfigMutation,
  useLazyDownloadFileQuery,
} = globalApi;
