import { Tag } from 'antd';
import React from 'react';

interface Props {
  status: number;
  text: string | number;
  width?: string;
}

const textColors = {
  0: 'default',
  1: 'success',
  2: 'error',
  3: 'magenta',
  4: 'purple',
  5: 'orange',
  6: 'geekblue',
  7: 'blue',
  8: 'cyan',
  9: 'lime',
  10: 'gold',
  11: 'error',
  12: 'volcano',
};

const StatusCard: React.FC<Props> = ({ status, text }) => {
  return (
    <Tag color={textColors[status as keyof typeof textColors]} key={status}>
      {text}
    </Tag>
  );
};

export default StatusCard;
